<template>
  <v-container fluid>
    <h1 class="titulo"> Prêmio Pago Baixo </h1>

    <v-card elevation="5">
      <v-card-text>
        <v-row no-gutters justify="start">
          <v-col md="2" class="mt-5" style="position: relative; top: -2px">
            <v-menu v-model="menu" transition="slide-y-transition" class="w-00" :close-on-content-click="true" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datesLabel"
                label="Data:"
                class="pt-0 mt-0 d-inline-block w-100"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                readonly
                >
              </v-text-field>
            </template>

              <v-date-picker
                v-model="dtStart"
                :color="variables.colorPrimary"
                no-title
                locale="pt-br"
                class="w-100"
                scrollable >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="2" class="ml-4">    
            <v-select
              class="w-100"
              :items="lotteries"
              label="Loteria"
              v-model="selectedLottery"
              :loading="loadSweepstakes"
              :error-messages="errorsSelect"
            />
          </v-col>
          <v-col md="2" class="ml-4 mt-4">
            <autoCompleteEntities :errorMessages="errorsEntity" :hideDetails="true"  :isOutlined="false" :entityProps.sync="entityId" :typesEntities="['4', '3']" />
          </v-col>
          <v-col md="2" class="ml-4">
            <v-text-field placeholder="COD. Bilhete" v-model="nrjogo" />
          </v-col>

          <v-btn class="mt-3"
            style="margin-left: 16px;"
            :loading="loading"
            :disabled="loading"
            :color="variables.colorPrimary"
            @click="enviar()"
            :dark="!loading">
            BUSCAR
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <v-skeleton-loader
      type="table"
      style="margin-top: 16px;"
      :loading="loading"
      :transition-group="'fade-transition'">
      <v-card elevation="5">
        <v-list-group
          v-for="(prizes, key) in prizeListMap"
          :key="key"
          v-model="listStatus[key]"
          no-action
          sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-row no-gutters>
                <h2 style="font-weight: bold; text-transform: uppercase;">{{key}}</h2>
                <h4 style="font-weight: bold; margin-left: 8px; align-self: center; color: green">
                  - Total baixado:{{totalAmountDownloaded[key] | currency}}
                </h4>
              </v-row>
            </v-list-item-content>
          </template>

          <v-list-item sub-group>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"> N° </th>
                      <th class="text-center">Revenda / Pagamento</th>
                      <th class="text-left">Nº Jogo / token</th>
                      <th class="text-left">Loteria</th>
                      <th class="text-left">valor do Jogo (R$)</th>
                      <th class="text-left">Valor do Prêmio (R$)</th>
                      <th class="text-left">Valor do Bônus (R$)</th>
                      <th class="text-left">Data do Sorteio</th>
                      <th class="text-left">Trans / Dt. Solicitação</th>
                      <th class="text-left">Atualizado</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(prize, index) in prizes" :key="index">
                      <td> {{ index + 1 }} </td>
                      <td class="text-center"> {{ getEntityOrigin(prize) }} {{ getEntityPayament(prize) }}</td>
                      <td>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"                               
                            >{{ prize.winners ? `${prize.winners.games.gameNumber} / ${prize.winners.codBilhete}` : prize.wpr.token }}</span>
                          </template>
                          <span>{{ prize.id }} </span>
                        </v-tooltip>
                       
                      </td>
                      <td>{{ prize.winners ? prize.winners.sweepstake.cod : '-' }}</td>
                      <td>{{ getGuessValue(prize) }}</td>
                      <td>{{ prize.value | currency }}</td>
                      <td>{{ prize.bonus ? parseFloat(prize.bonus.value) : '-'  | currency }}</td>
                      <td> {{ getDataSorteio(prize) }}</td>
                      <td> {{ getDataCreated(prize) }} </td>
                      <td>{{prize.updated | datas}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-list-item>
        </v-list-group>
      </v-card>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// eslint-disable-next-line
import { sum, map, size } from 'lodash'
import moment from 'moment'
// eslint-disable-next-line
import { currency } from '@/core/service/utils'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

const namespaceStore = 'premioPagoCentral'

export default {
  name: 'PremioPagoBaixo',
  directives: { mask },

  components: {
    // dateTimerPicker: () => import('@/views/components/dateTimePicker'),
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
  },
  data: () => ({
    itens: 1,
    loading: false,
    datesLabel: moment().format('DD/MM/YYYY'),
    dtStart: '',
    menu: false,
    entityType: 0,
    entityId: null,
    totalAmountDownloaded: {},
    listStatus: {},
    selectedLottery: null,
    loadSweepstakes: false,
    nrjogo: null
  }),
  mounted () {
    this.dtStart = moment().format('YYYY-MM-DD')
    this.datesLabel = moment().format('YYYY-MM-DD')
  },
  computed: {
    ...mapGetters('relatorioResultadosBingo', ['lotteries']),
    ...mapGetters('premioPagoBaixo', ['prizeListMap']),
    ...mapGetters(namespaceStore, ['supervisorList', 'promoterList', 'agencyList']),
    errorsSelect() {
      const errors = []
      if (!this.$v.selectedLottery.$dirty) return errors
      !this.$v.selectedLottery.required && errors.push('Campo obrigatório!')
      return errors
    },
    errorsDate() {
      const errors = []
      if (!this.$v.dtStart.$dirty) return errors
      !this.$v.dtStart.required && errors.push('Campo obrigatório!')
      return errors
    },
    errorsEntity() {
      const errors = []
      if (!this.$v.entityId.$dirty) return errors
      !this.$v.entityId.required && errors.push('Campo obrigatório!')
      return errors
    },
     user () {
      return this.$store.getters['auth/user']
    },
    variables: () => variables
  },

  watch: {
    'prizeListMap' (val) {
      const self = this

      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          self.listStatus[key] = true
          self.totalAmountDownloaded[key] = sum(map(val[key], item => parseFloat(item.value)))
        }
      }
    },
    'dtStart' (val) {
      this.loadSweepstakes = true
      this.selectedLottery = null
      this.datesLabel = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.getAllLotteries({
        date: val
      }).finally(() => {this.loadSweepstakes = false })
    }
  },

  methods: {
    ...mapActions('premioPagoBaixo', [ 'getPremiosBaixos']),
    ...mapActions('relatorioResultadosBingo', [ 'getAllLotteries']),
    closeModalDatePicker (date) {
      this.dtStart = moment(date,'YYYY-MM-DD').format('DD/MM/YYYY')
      this.menuDate = false
    },

    testeDate (date) {
      const testeDate =  moment(date,'YYYY-MM-DD')
      if (testeDate.isValid()) this.dtStart = moment(date).format('YYYY-MM-DD')
    },

    buscarLoterias () {
      const self = this

      self.loading = true
      self.getSweepstakesRecurrent({ order: { id: 'desc' } }).then((result) => {
        self.loteryList = result.data.resultMap.map(item => ({ text: `${item.id} - ${item.cod}`, value: item.id }))
        self.selectedLottery = self.loteryList[0]
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        self.loading = false
      })
    },

    enviar () {
      const self = this

      // if (this.$v.selectedLottery.$invalid || this.$v.entityId.$invalid) {
      if (this.$v.selectedLottery.$invalid) {
        return this.$v.selectedLottery.$touch()
      }

      self.loading = true
        
      if (!this.dtStart) return this.all
      const data = {
        dtInicial: moment(this.dtStart).format('YYYY-MM-DD HH:mm:ss'),
        dtFinal: moment(this.dtStart).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
        userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1,
        entityId: this.entityId ? this.entityId.value : undefined,
        nrjogo: this.nrjogo || undefined,
        sweepstake: this.selectedLottery
      }

      self.getPremiosBaixos(data).then(() => {}).catch(e => { console.error(e) }).finally(() => {
        self.loading = false
      })
    },

    buscarUsuarios () {
      const self = this
      self.loading = true
      self.getUsers().then(() => {}).catch(e => { console.error(e) }).finally(() => {
        self.loading = false
      })
    },

    getEntityOrigin (item) {
      if (item.winners && item.winners.games)
        return item.entity.entityTypeId === 6 ? 'WEB' : item.winners.games.entity.cod
      else 
        return item.entity.cod
    },

    getEntityPayament (item) {
      if (item.winners && item.winners.games){
        if (item.winners.games.entity.cod !== item.entity.cod)  {
          return `/ ${item.entity.cod}`
        }
      } else {
        return `/ ${item.wpr.entityPayer.cod}`
      }

      return 
    },

    getGuessValue (prize) {
      return prize.winners ? currency(prize.winners.totais.guessValue) : '-' 
    },

    getDataSorteio (prize) {
      return prize.winners ? moment(prize.winners.sweepstake.dtDraw).format('DD/MM/YYYY HH:mm')  : '-' 
    },

    getDataCreated (prize) {
      return prize.winners ? moment(prize.winners.games.created).format('DD/MM/YYYY HH:mm')  :  moment(prize.wpr.created).format('DD/MM/YYYY HH:mm')
    }
  },
  validations () {
    return  {
      selectedLottery: { required },
      dtStart: { required },
      entityId: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .radioGroupEntity::v-deep {
    .v-input--radio-group__input {
      justify-content: space-between;
    }
  }
  .v-input{
    width: 100%;
  }
</style>